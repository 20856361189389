import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import { validate } from 'uuid'

const CLSelection = ({
  crossingLines,
  newCrossingLines,
  handleCrossingLinesChange,
  multi,
  hideDevice = false,
  invalid = false
}) => {
  const { t } = useTranslation()
  const { Option } = Select
  const [inputValue, setInputValue] = useState('')

  const attributes = {}
  multi && (attributes['mode'] = 'multiple')

  return (
    <Select
      optionFilterProp="label"
      allowClear
      value={newCrossingLines}
      onChange={handleCrossingLinesChange}
      onBlur={() => setInputValue('')}
      onSearch={setInputValue}
      key="input"
      className={invalid ? 'scc--solutions--selection-invalid' : ''}
      placeholder={t(
        'solutions.scenes.overview.add.data.crossingLine.addPlaceholder'
      )}
      style={{ width: '100%' }}
      {...attributes}
    >
      <Option value="default" key="default" disabled>
        {crossingLines && crossingLines.length > 0 ? (
          <>
            <span className="scc--solutions--add-stream-option">
              {t('solutions.scenes.overview.add.data.crossingLine.addLegendCL')}
            </span>
            {t(
              'solutions.scenes.overview.add.data.crossingLine.addLegendStream'
            )}
            {!hideDevice &&
              t(
                'solutions.scenes.overview.add.data.crossingLine.addLegendDevice'
              )}
          </>
        ) : (
          t('solutions.scenes.overview.add.data.crossingLine.emptyLegend')
        )}
      </Option>

      {crossingLines &&
        crossingLines.map((crossingLine) => {
          let lineName =
            crossingLine.crossinglineName &&
            crossingLine.crossinglineName.length
              ? crossingLine.crossinglineName
              : t('draw.form.crossingLine.name.placeholder')
          let streamName =
            crossingLine.streamName && crossingLine.streamName.length
              ? crossingLine.streamName
              : t('configuration.group.stream.streamname.placeholder')
          return (
            <Option
              label={`${crossingLine.crossinglineId} - ${lineName} - ${streamName} - ${crossingLine.boxName}`}
              value={crossingLine.crossinglineId}
              key={crossingLine.crossinglineId}
            >
              <span className="scc--solutions--add-stream-option">
                {lineName}
              </span>
              &nbsp;- {streamName}
              {!hideDevice && <>&nbsp;- {crossingLine.boxName}</>}
            </Option>
          )
        })}
      {inputValue && validate(inputValue) && (
        <Option value={inputValue} key={inputValue} label={inputValue}>
          <span className="scc--solutions--add-stream-option">
            {t('solutions.scenes.overview.add.data.crossingLine.addUuid')}
          </span>
          &nbsp;- {inputValue}
        </Option>
      )}
    </Select>
  )
}

export default CLSelection
